import React, { Component } from "react";
import styles from "./detailBloodStationPlan.module.scss";
import { inject, observer } from "mobx-react";
import AuthStore from "../../../stores/AuthStore";
import BloodStationStore from "../../../stores/BloodStationStore";
import plural from "plural-ru";
import { User } from "../../../entities";
import PlannedStore from "../../../stores/PlannedStore";
import { Modal } from "semantic-ui-react";
import redirectTo from "../../../utils/redirectTo";
import moment from "moment";
import { sendMetrics, wordsByGender } from "../../../utils/misc";
import MyButton from "../../myButton";
import UserAvatar from "../../ui/userAvatar";

@inject("authStore")
@inject("plannedStore")
@inject("bloodStationsStore")
@observer
export default class DetailBloodStationPlan extends Component<any, any> {
  props: {
    authStore?: AuthStore;
    bloodStationsStore?: BloodStationStore;
    plannedStore?: PlannedStore;
    id: number | string;
    isMobile: boolean;
  };

  state: {
    totalCount: number;
    users: User[];
    modalOpen: boolean;
    planedDonation: any;
  };
  private Timeout: NodeJS.Timeout;
  private modalTimeout: NodeJS.Timeout;

  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      users: null,
      modalOpen: false,
      planedDonation: null,
    };
  }

  onClickNext = (e) => {
    e.stopPropagation()
    sendMetrics("to_login_from_bs");
  };

  onClickNextPlan = (e) => {
    e.stopPropagation()
    sendMetrics("to_planning_from_bs");
    this.props.plannedStore
      .loadPlan()
      .then((r) => {
        this.setState({
          modalOpen: true,
          planedDonation: r,
        });
      })
      .catch(() => {
        redirectTo(`/profile/planning/?bsID=${this.props.id}`);
      });
  };

  componentDidMount() {
    // console.log(window.location.pathname)
    this.props.bloodStationsStore
      .getPlannedUserCount(this.props.id, true)
      .then((r) => {
        this.setState({
          totalCount: r.count,
          users: r.results.map((user: User) => user),
        });
      });
  }

  componentWillUnmount() {
    clearTimeout(this.modalTimeout);
    clearTimeout(this.Timeout);
  }

  userOutput = () => {
    const { users } = this.state;
    const userCount = users?.length;
    if (userCount >= 3) {
      const preOut = users
        .slice(0, 2)
        .map((user) => user.first_name)
        .join(", ");
      if (userCount === 3)
        return (
          preOut +
          ` и ${users[2]?.first_name} стали донорами и запланировали сдать кровь здесь`
        );
      return (
        preOut +
        ` и еще ${userCount - 2} ${plural(
          userCount - 2,
          "донор",
          "донора",
          "доноров"
        )} ${plural(
          userCount - 2,
          "запланировал",
          "запланировали",
          "запланировали"
        )} сдать кровь здесь`
      );
    }
    if (userCount === 2)
      return `${users[0]?.first_name} и ${users[1]?.first_name} уже стали донорами и запланировали сдать кровь здесь`;
    if (userCount === 1)
      return `${users[0]?.first_name} уже ${wordsByGender(
        users[0]?.first_name,
        "стал",
        "стала"
      )} донором и ${wordsByGender(
        users[0]?.first_name,
        "запланировал",
        "запланировала"
      )} сдать кровь здесь`;
  };

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  render() {
    const {
      props,
      state,
      userOutput,
      onClickNext,
      toggleModal,
      onClickNextPlan,
    } = this;
    const { authStore, isMobile, id } = props;
    const { users, modalOpen, planedDonation } = state;
    const userImages = users ? users.slice(0, 3) : null;
    return (
      <div className={styles.DetailBloodStationPlan}>
        <div className={styles.DetailBloodStationPlan_Button}>
          {authStore?.user && (
            <MyButton
              text={"Запланировать донацию"}
              onClick={(e) => onClickNextPlan(e)}
              width={"100%"}
              containerWidth={"100%"}
            />
          )}
          {!authStore?.user && (
            <MyButton
              text={"Присоединиться"}
              withLink={`/login/?utm_source=main_page&utm_medium=bloodstations_widget&utm_campaign=donor_search&next=${window.location.pathname}`}
              isSSR
              onClick={(e) => onClickNext(e)}
              width={"100%"}
              containerWidth={"100%"}
            />
          )}
        </div>
        {users?.length > 0 && (
          <div className={styles.DetailBloodStationPlan_Planned}>
            <div className={styles.DetailBloodStationPlan_Planned_Text}>
              <span>{userOutput()}</span>
            </div>
            <div className={styles.DetailBloodStationPlan_Planned_Images}>
              {userImages &&
                userImages.map((user, i) => {
                  return (
                    <UserAvatar
                      key={`user_${user.id}_${i}`}
                      user={user}
                      scale={44}
                      style={{ width: "auto" }}
                    />
                  );
                })}
            </div>
          </div>
        )}
        <Modal
          style={
            isMobile
              ? {
                  top: "auto",
                  width: "100%",
                  margin: 0,
                  height: "auto",
                  bottom: 0,
                  borderRadius: "6px 6px 0 0",
                }
              : {
                  top: "auto",
                  bottom: "auto",
                  margin: 0,
                  height: "auto",
                  left: "auto",
                  right: "auto",
                  maxWidth: "400px",
                }
          }
          open={modalOpen}
          onOpen={toggleModal}
          onClose={toggleModal}
        >
          <Modal.Content>
            <div className={styles.Modal}>
              <div className={styles.Modal_Close} onClick={toggleModal}>
                <img src="/static/images/mainModalClose.svg" alt="" />
              </div>
              <div className={styles.Modal_Head}>
                <h3>Вы уже запланировали донацию</h3>
                {planedDonation && (
                  <div className={styles.Modal_Head_Planed}>
                    <div className={styles.Modal_Head_Planed_Date}>
                      {moment(planedDonation?.plan_date).format(
                        "dd, DD MMMM YYYY"
                      )}
                    </div>
                    <div className={styles.Modal_Head_Planed_Title}>
                      {planedDonation?.blood_station?.title
                        ? planedDonation?.blood_station?.title
                        : "Выездная акция"}
                    </div>
                    {planedDonation?.blood_station && (
                      <div className={styles.Modal_Head_Planed_Address}>
                        {(planedDonation?.blood_station?.city?.title
                          ? planedDonation?.blood_station?.city?.title + ", "
                          : null) + planedDonation?.blood_station?.address}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.Modal_Body}>
                <button
                  className={styles.Modal_Body_Decline}
                  onClick={toggleModal}
                >
                  Оставить как есть
                </button>
                <MyButton
                  text={"Изменить"}
                  fontWeight={"normal"}
                  withLink={`/profile/change_plan/?bsID=${id}`}
                  isSSR
                  padding={"12px 24px"}
                  width={"100%"}
                  containerWidth={"100%"}
                  adjustHeight={"auto"}
                />
              </div>
            </div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
